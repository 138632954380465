<template>
  <v-app id="app">
    <v-app-bar color="white" light app clipped-left>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="!isMobile"
      ></v-app-bar-nav-icon>
      <img src="../assets/logo.svg" alt="" height="30" class="card_image" />
      <v-spacer></v-spacer>
      <v-avatar color="#EEECFF" size="38">
        <span>{{
          userName.split(" ")[0].charAt(0) + userName.split(" ")[1].charAt(0)
        }}</span>
      </v-avatar>
      <span class="mx-2">{{ userName }}</span>
      <v-icon @click="expand = !expand" color="primary"
        >mdi-chevron-down</v-icon
      >
      <v-expand-transition>
        <v-card v-show="expand" class="mx-auto pa-4 dropDown_profile">
          <div>
            <!-- <div>
              <v-btn text large> Profile </v-btn>
            </div>
            <v-divider></v-divider>
            <div>
              <v-btn text large> Change Password </v-btn>
            </div> -->
            <v-divider></v-divider>
            <div>
              <v-btn text large @click.prevent="logout"> Logout </v-btn>
            </div>
          </div>
        </v-card>
      </v-expand-transition>
    </v-app-bar>
    <v-expand-transition>
      <v-card
        class="snackbar pa-5"
        :color="snackbar.color"
        v-show="snackbar.showSnackbar"
        width="100vw"
      >
        <h5 class="text-center">{{ snackbar.message }}</h5>
        <v-btn icon color="black" @click="closeSnackBar" class="close_btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-expand-transition>
    <v-navigation-drawer
      color="primary"
      v-if="!isMobile"
      app
      clipped
      v-model="drawer"
      stateless
      bottom
      disable-resize-watcher
      :temporary="isMobile"
    >
      <v-list dense dark rounded class="mt-12">
        <v-list-item @click.stop="drawer = false" v-if="isMobile">
          <v-list-item-icon>
            <v-icon>{{ close.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.route"
          class="my-2"
          exact
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-bottom-navigation
      app
      background-color="white"
      color="primary"
      v-if="isMobile"
    >
      <v-btn v-for="(item, index) in items" :key="index" :to="item.route" exact>
        <span>{{ item.title }}</span>
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!-- Sizes your content based upon application components -->
    <v-main class="main_app">
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      expand: false,
      disable: true,
      drawer: false,
      close: { icon: "mdi-close" },
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", route: "/admin" },
        {
          title: "Savers",
          icon: "mdi-account-multiple",
          route: "/admin/savers",
        },
        {
          title: "Agents",
          icon: "mdi-account-plus-outline",
          route: "/admin/agents",
        },
        // { title: "Wallet", icon: "mdi-wallet", route: "/admin/wallet" },
        // { title: "Payments", icon: "mdi-web", route: "/admin/payments" },
      ],
    };
  },
  created() {
    if (!this.isNotDesktop) {
      this.drawer = true;
    }
  },
  methods: {
    ...mapMutations("snackbar", ["CLOSE_SNACKBAR"]),
    clickHandler(e) {
      console.log(e);
      return (this.drawer = false);
    },
    logout() {
      sessionStorage.removeItem("ajoToken");
      sessionStorage.removeItem("ajoData");
      window.location.href = "/login";
    },
    closeSnackBar() {
      this.CLOSE_SNACKBAR();
    },
  },
  computed: {
    ...mapGetters("agent", ["agent"]),
    ...mapGetters("snackbar", ["snackbar"]),
    isNotDesktop() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    closeConditional(e) {
      return this.drawer;
    },
    userName() {
      return `${
        this.agent.first_name.charAt(0).toUpperCase() +
        this.agent.first_name.slice(1)
      } ${
        this.agent.last_name.charAt(0).toUpperCase() +
        this.agent.last_name.slice(1)
      }`;
    },
  },
};
</script>

<style scoped>
.snackbar {
  height: 8vh;
  z-index: 10;
  position: absolute;
}
.snackbar h5 {
  color: white !important;
}
.close_btn {
  position: absolute;
  right: 2rem;
  top: 10px;
}
.dropDown_profile {
  position: absolute;
  right: 1rem;
  top: 4rem;
}
span {
  color: #272253;
  font-weight: 600;
}
.container--fluid {
  padding: unset !important;
}
.v-application p {
  margin: 0 0 !important;
}
.v-btn--round {
  border-radius: 10%;
}
</style>